import Vue from 'vue';
import App from './App.vue';
//import "./registerServiceWorker";
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from '@/plugins/i18n';

import * as filters from './filters';

import '@/styles/index.scss';
import VueVideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';

import DatetimePicker from 'vuetify-datetime-picker';
import toastInit from '@/components/toast';
import { init as extendInit } from '@/utils/extend';
extendInit();
import VueGtag from 'vue-gtag';
import { addIRISNetwork } from '@/keplr/wallet.js';
addIRISNetwork();

Vue.use(
  VueGtag,
  {
    config: { id: 'G-SVCSEKJLZ3' },
  },
  router
);

import {
  timestampToDate,
  timestampToDateTime,
  timestampToTimeHour,
  timestampToTimeMinute,
  timestampToTimeSecond,
} from '@/utils/helper.js';
Vue.prototype.timestampToDate = timestampToDate;
Vue.prototype.timestampToDateTime = timestampToDateTime;
Vue.prototype.timestampToTimeHour = timestampToTimeHour;
Vue.prototype.timestampToTimeMinute = timestampToTimeMinute;
Vue.prototype.timestampToTimeSecond = timestampToTimeSecond;

// import rsa from "@/utils/RSAEncrypt.js";
// Vue.prototype.$rsa = rsa;

window.eventBus = new Vue(); //注册全局事件对象

// lj ## uptick全局引入。改动在切换币种事件调用
// import { init as keplrInit } from '@/keplr';
// keplrInit("UPTICK");

// const uptick1155 = require('./metaMask/evm/handler/uptick1155.js');
// (async ()=>{
//     await uptick1155.mint(126,100,"0x1234");
// })()

// 自定义全局组件 START
import uToast from '@/components/uToast.vue';
Vue.component('uToast', uToast);

// import basePage from "@/layout/BasePage.vue";
import uComponents from '@/components/uComponents.vue';
Vue.component('uComponents', uComponents);

import promptBox from '@/components/PromptBox.vue';
Vue.component('promptBox', promptBox);

// 全局提示框
//     type:success,error
Vue.prototype.$toast = function (type, txt, timer) {
  return this.$refs.ucom.toast(type, txt, timer);
};
// 全局确认框
Vue.prototype.$confirm = function (txt, ps) {
  this.$refs.ucom.confirm(txt, ps);
};

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import messageTip from './components/MessageTip';
messageTip(Vue);

toastInit(Vue, {
  property: '$toastFn',
});

Vue.use(DatetimePicker);

Vue.use(VueVideoPlayer);

Vue.config.productionTip = false;

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
  async mounted() {},
}).$mount('#app');
